<template>
  <miracle/>
</template>

<script>
import Miracle from "@/components/Miracle";

export default {
  name: 'Home',
  components: {
    Miracle
  }
}
</script>
